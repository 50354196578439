del {
  color: #999999;
}

.mg-top {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.spinner-border {
  margin: 30px calc((100% - 36px) / 2)
}

@import url(https://fonts.googleapis.com/earlyaccess/notosansjapanese.css);
@import url(https://fonts.googleapis.com/css2?family=M+PLUS+2&display=swap);
body {
  font-family: "M PLUS 2", Helvetica, "Noto Sans Japanese", sans-serif;
}

.default-header {
  height: 48px;
  padding: 8px;

  background: linear-gradient(90deg, #ECEDEA 0%, #F8F8F6 49.48%, #DCDBD8 100%);

  .container-fluid:first-child {
    height: 48px;
    min-height: 0;

    img.logo {
      height: 30px;
    }

    nav {
      height: 48px;
      font-size: 15px;

      .header-button-register {
        padding: 8px 16px;
        font-size: 12px;
        line-height: 20px;
        width: 96px;
        height: 40px;
        /* gradation */
        background: linear-gradient(90deg, #0F6BBF 0%, #4285EC 100%);
        border-radius: 30px;
      }
    }
  }
}

.app-header {
  height: 48px;
  padding: 8px;

  .corporation-selector {
    width: auto;
    margin-left: 8px;
  }

  .container-fluid:first-child {
    height: 48px;
    min-height: 0;
    justify-content: normal;

    nav {
      height: 48px;
    }
  }
  .username {
    margin-right: 15px;
  }
}


.sidebar {
  .sidebar-brand {
    padding: 15px;
    background-color: white;
  }
}

.cv-container {
  display: inline-flex;
  justify-content: center;
  margin: 80px 0 120px 0;
}

.cv-button {
  padding: 8px 16px;
  margin-top: 10px;
  font-size: 22px;
  line-height: 33px;
  width: 191px;
  height: 54px;
  /* gradation */
  background: linear-gradient(90deg, #0F6BBF 0%, #4285EC 100%);
  border-radius: 30px;
}

h2.accent-1 {
  margin-top: 120px;
  display: block;
  text-align: center;
  font-size: 40px;
}

span.accent-2 {
  display: block;
  font-size: 20px;
  text-align: center;
  color: #274765;
}

// Top
.lp-top {
  width: 100%;
  height: 668px;
  margin: 0;
  max-width: none;
  display: inline-flex;
  background: linear-gradient(90deg, #ECEDEA 0%, #F8F8F6 49.48%, #DCDBD8 100%);

  .lp-top-left {
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: center;

    .sentence {
      font-size: 48px;
      margin: auto;
      margin-left: 5%;
    }

    .lp-button-register {
      padding: 8px 16px;
      margin-top: 10px;
      font-size: 22px;
      line-height: 33px;
      width: 191px;
      height: 54px;
      /* gradation */
      background: linear-gradient(90deg, #0F6BBF 0%, #4285EC 100%);
      border-radius: 30px;
    }
  }

  .lp-top-right {
    width: 60%;
    height: 100%;
    margin: 0;
    margin-right: 4%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../assets/image/top.png');
  }
}

.lp-concept {
  height: 427px;
  margin: 0;
  max-width: none;
  display: inline-flex;
  background-color: #ffffff;

  .lp-concept-left {
    width: 40%;
    margin-top: 120px;
    margin-left: 4%;
    background-size: 250px;
    background-repeat: no-repeat;
    background-position: top;
    background-image: url('../assets/image/logo_yoko.png');
  }

  .lp-concept-right {
    margin-top: 107px;

    h2 {
      font-size: 40px;
      margin-bottom: 40px;
    }

    span {
      font-size: 15px;
      margin-bottom: 12px;
      display: block
    }
  }
}

.lp-feature {
  margin: 0;
  margin-top: -6px;
  max-width: none;
  display: grid;
  justify-content: center;
  background-color: #F8F8F6;

  .lp-feature-box {
    width: 1100px;
    height: 474px;
    margin-top: 80px;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    display: flex;

    .lp-feature-box-left {
      width: 48%;
      height: 314px;
      margin: 80px 2% 80px 64px;
    }
    .lp-feature-box-right {
      width: 48%;
      height: 314px;
      margin: 80px 64px 80px 2%;
      h3 {
        padding-left: 20px;
      }
      span {
        padding-left: 20px;
      }
    }
    .feature-img1 {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('../assets/image/feature01.png');
    }
    .feature-img2 {
      height: 100%;
      margin: 0;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 24px 72px;
      background-image: url('../assets/image/feature02.png');
    }
    .feature-img3 {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('../assets/image/feature03.png');
    }
    .number {
      font-size: 64px;
      color: #0F6BBF;
      display: block;
      margin-top: -24px;
    }
    span {
      margin-bottom: 8px;
      display: block;
    }
    h3 {
      margin-bottom: 20px;
      font-size: 40px;
      color: #222222;
    }
  }
}

.lp-price {
  margin: 0;
  max-width: none;
  display: grid;
  justify-content: center;
  background-color: #ffffff;

  table {
    border-collapse: collapse;
    font-size: 15px;
    margin-top: 80px;
    tr {
      height: 70px;
      border-bottom: solid 1px #999999;

      &:last-child th{
        vertical-align: top;
        padding-top: 25px;
      }
    }
    th,td {
      padding: 10px
    }
    th {
      color: #999999;
      width: 160px;
    }
    strong.number {
      font-size: 40px;
      color: #0F6BBF;
    }
    ul {
      margin-top: 15px;
      margin-left: -20px;
    }
  }
}

.lp-recommend {
  margin: 0;
  max-width: none;
  display: grid;
  justify-content: center;
  background-color: #F8F8F6;

  span {
    display: block;
    text-align: center;
  }

  .recommend-row {
    max-width: 880px;
    margin: auto;
  }
  .recommend-box {
    width: 280px;
    margin-top: 80px;
    margin-bottom: 60px;
    display: grid;
    justify-content: center;
    div {
      height: 120px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
    span {
      font-size: 20px;
    }
    .recommend-img1{
      background-image: url('../assets/image/recommend01.png');
      background-position:22px 10px;
    }
    .recommend-img2{
      background-image: url('../assets/image/recommend02.png');
    }
    .recommend-img3{
      background-image: url('../assets/image/recommend03.png');
    }
  }
}
.lp-vision {
  height: 1120px;
  margin: 0;
  max-width: none;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../assets/image/vision.png');
  .accent-1,.accent-2 {
    color: #FFFFFF;
  }
  .vision-box {
    width: 728px;
    margin-top: 80px;
    padding: 80px 60px 80px 60px;
    color: #FFFFFF;
    background: rgba(0, 0, 0, 0.7);
    div {
      margin-bottom: 40px;
    }
    span {
      display: block;
      font-size: 16px;
      line-height: 28px;
    }
    .title span{
      font-size: 40px;
      font-weight: 400;
      line-height: 3.5rem;
    }
    ul {
      padding-left: 20px;
      li {
        margin-bottom: 20px;
      }
    }
  }
}

.lp-faq {
  margin: 0;
  max-width: none;
  display: grid;
  justify-content: center;
  background-color: #F8F8F6;

  .accordion {
    margin-top: 60px;
    .accordion-item {
      width: 600px;
      .accordion-header {
        height: 92px;
        display: flex;
      }
    }
    strong {
      margin-right: 5px;
      &.blue {
        color: #0f6bbf;
      }
      &.red {
        color: #ff1154;
      }
    }
  }
}

.about {
  margin: 0;
  padding-top: 80px;
  max-width: none;
  display: grid;
  justify-content: center;
  background-color: #ffffff;

  .row {
    height: 50px;

    .accent-1 {
      margin-top: 0;
    }
  }

  table {
    border-collapse: collapse;
    font-size: 15px;
    width: 500px;
    margin-bottom: 60px;
    tr {
      height: 70px;
      border-bottom: solid 1px #999999;

      &:last-child th{
        vertical-align: top;
        padding-top: 25px;
      }
    }
    th,td {
      padding: 10px
    }
    th {
      color: #999999;
      width: 160px;
    }
  }
}

.login {
  margin: 0;
  padding-top: 80px;
  max-width: none;
  display: grid;
  justify-content: center;
  background-color: #ffffff;

  .login-card {
    margin-top: 60px;
    margin-bottom: 60px;
    button {
      background-color: #0F6BBF;
    }
    a {
      font-size: 14px;
      text-decoration: underline;
    }
  }

  .accent-1 {
    margin-top: 0;
  }

  .left {
    background-color: #F8F8F6;
  }
  .right {
    color: #FFFFFF;
    background-color: #274765;
  }
}

.signup {
  margin: 0;
  padding-top: 80px;
  max-width: none;
  display: grid;
  justify-content: center;
  background-color: #ffffff;

  .card {
    margin-top: 60px;
    margin-bottom: 60px;
    button {
      background-color: #0F6BBF;
    }
  }

  .accent-1 {
    margin-top: 0;
  }

}

.default {
  color: #FFFFFF;
  background-color: #274765;
  display: grid;
  justify-content: center;
  ul {
    margin-top: 60px;
    flex-direction: row;
    font-size: 12px;
    li {
      margin-right: 20px;
      margin-left: 20px;
    }
  }

  .footer-logo {
    margin: auto;
    margin-top: 60px;
    width: 160px;
    height: 27px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../assets/image/logo_moji_wh.png');
  }

  span.accent-2 {
    margin-top: 40px;
    display: block;
    font-size: 10px;
    text-align: center;
    color: #FFFFFF;
  }
}

// Dashboard
@mixin svg-color($color) {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="300" height="73" viewBox="0 0 300 73" xml:space="preserve"><g transform="matrix(4.76 0 0 3.09 150.26 36.58)" id="CH1e_PnBcHy_P_oz7amoF"  ><path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(42,64,115); fill-rule: nonzero; opacity: 1;" vector-effect="non-scaling-stroke"  transform=" translate(0, 0)" d="M 31.52017 -0.0285 L 14.375820000000001 -11.923499999999999 L -31.520169999999997 -11.790059999999999 L -31.520169999999997 11.737510000000002 L 14.375820000000001 11.923510000000002 z" stroke-linecap="round" /></g></svg>');
  background-repeat: no-repeat;
  background-size: contain
}

.card {
  margin-bottom: 40px;
}

.welcome {
  .card-header {
    text-align: center;
  }

  .title {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .row.image {
    height: 200px;
    margin-top: 20px;
    margin-bottom: 20px;

    svg {
      height: 100%;
    }
  }

  button {
    width: 100%;
  }
}

.dashboard {
  margin-top: 15px;
  margin-bottom: 40px;

  a {
    float: right;
  }

  .progress-row {
    &>div {
      display: inherit;
      .progress {
        position: relative;
        right: 0px;
        margin-top: 5px;
        margin-right: 2px;
        margin-left: 10px;
        width: 80%;
      }
      div:nth-child(2) {
        margin-left: auto;
        width: 70px;
      }
    }
  }

  .stresscheck-fy {

    h4 {
      margin: auto;
    }

    .card {
      height: 100%;
    }

    .card-header {
      background-color: #2a4073;
      color: white;
      text-align: center;
      height: 50px;
    }

    .card-body {
      text-align: center;
      font-size: 30px;
      line-height: 2.5rem;
      padding: 0;
      padding-top: 40px;
    }

    >div:first-child {
      background-color: white;
    }

    .col {
      border-right: 1px dashed #2a4073;
    }

    .box-arrow {
      height: 4rem;
      &+p+div {
        margin-top: 13px;
        select.form-select {
          float: right;
          width: auto;
        }
      }
      &>div{
        display: inline-block;
        position: relative;
        color: white;
        padding-left: 20px;
        line-height: 50px;
        width: 90%;
        height: 50px;
        background-color: #2a4073;
        //@include svg-color("#2a4073");
        &::after {
          content: '';
          position: absolute;
          right: -30px;
          height: 50px;
          border-top: 25px solid transparent;
          border-bottom: 25px solid transparent;
          border-left: 30px solid #2a4073;
        }
      }
      &>div.inactive{
        display: inline-block;
        position: relative;
        color: #555555;
        padding-left: 20px;
        line-height: 50px;
        width: 90%;
        height: 50px;
        background-color: #cccccc;
        //@include svg-color("#2a4073");
        &::after {
          content: '';
          position: absolute;
          right: -30px;
          height: 50px;
          border-top: 25px solid transparent;
          border-bottom: 25px solid transparent;
          border-left: 30px solid #cccccc;
        }
      }
    }
  }
}

.add-target {
  .modal-content {
    height: 80%;
  }
}

table.dashboard {
  th:not(:first-child) {
    text-align: right;
  }

  td:not(:first-child) {
    text-align: right;
    padding-right: 5px;
  }

  td.indent {
    padding-left: 1.5rem
  }
}

.status-area {
  margin: auto;
  margin-right: auto;
  text-align: right;

  div {
    display: inline-block;
  }
}

.status-block {
  margin-left: 10px;
  padding: 0.1em 1em;
  color: #ffffff;
}

.NotYet {
  background: #ea9999;
}

.InProgress {
  background: #FFD966;
}

.Done {
  background: #7CDF91;
}

.short-status {
  position: absolute;
  top: 4px;
  right: 10px;
  width: 30px;
  height: 30px;
  line-height: 29px;
  color: #ffffff;
  background: #ea9999;

  &.Done {
    background: #7CDF91;
  }
}

p.steps {
  margin-bottom: 0;
 text-align: center;
}

.step-svg {
  height: 300px;
  width: 100%;
  margin-top: 10px;
}

.circle-question {
  display: inline-block;
  background: #EEEEEE;
  border: #999999 1px solid;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-left: 10px;
  color: #999999;
  font-size: 15px;
  text-align: center;
  line-height: 25px;

  &:after {
    content: "?";
  }
}

table.employees-by-division-list {
  margin-bottom: 50px;
  tr:not(:first-child):not(:nth-child(2)) {
    td:first-child {
      padding-left: 1.5rem
    }
  }
  th:not(:first-child),td:not(:first-child) {
    text-align: right;
    padding-right: 20px;
  }
}

.M {
  display: inline-block;
  background: #6D9EEB;
  border: #6D9EEB 1px solid;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}
.F {
  display: inline-block;
  background: #DD7E6B;
  border: #DD7E6B 1px solid;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.employees-list {
  font-size: 15px;
}

.stresscheck-modal {
  .modal-dialog {
    max-width: 1200px;

    .modal-content {
      min-height: 600px;
    }

    form {
      margin-top: 10px;
      input,select {
        margin-bottom: 20px;
      }
    }
  }
}

.select-divisions {
  margin-bottom: 10px;
}

table.analysis {
  th:not(:first-child) {
    text-align: right;
  }

  td:not(:first-child) {
    text-align: right;
    padding-right: 5px;
  }

  td.indent {
    padding-left: 1.5rem
  }

  border-collapse: collapse;
  width: 100%;

  th {
    background-color: #2a4073;
    color: #ffffff;
    font-weight: normal;
  }
  th, td {
    padding-left: 10px;
  }

  &.analysis {
    th:not(:first-child) {
      text-align: left;
    }

    td:not(:first-child) {
      text-align: left;
      padding-right: 0;
    }
  }
}

.report {
  max-width: 500px;
  margin: 20px max(calc( (100vw - 500px) / 2) , 10px);
  h2 {
    font-size: 1.4rem;
    text-align: center;
    margin: auto;
  }
  button {
    display: block;
    margin: 0 0 0 auto;
    &.result {
      margin: auto;
      margin-top: 10px;
    }
  }
  label {
    font-weight: 500;
  }
  input {
    margin-bottom: 15px;
  }
  .alert {
    margin-top: 20px;
  }
  .category {
    font-weight: bold;
    margin-top: 30px;
  }
  .question {
    margin-left: 10px;
    margin-top: 20px;
  }
  .choice {
    padding-left: 3rem;
    margin-bottom: 15px;
  }
  .stress-level {
    margin: 18px;
    font-size: 30px;
  }

  svg {
    display: none;
  }
}

html:not([dir=rtl]) .form-check {
  padding-left: 3rem;
}

form.corporation-settings {
  margin-top: 5px;

  input,select {
    margin-bottom: 20px;
  }

  //スピンボタンの非表示
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance:textfield;
  }
}

.inline-button {
  pointer-events: none;
}

/* A4縦用のベース設定 */
@page {
  size: A4 portrait; /* 横の場合はlandscape */
  margin: 0mm;
}

/* 印刷1ページ用のコンテンツはここで定義 */

.page {
  visibility: hidden;
  height: 0;
  width: 0;
}

.page-doctor {
  visibility: visible;
  height: 1485;
  width:1050;
}

@media print {
  .page {
    visibility: visible;
    width: 210mm; /* 用紙の横幅を改めて指定 */
    height: 296.5mm;/* 高さには0.5mm余裕をもたせる */
    page-break-after: always;

    box-sizing: border-box;
    padding: 0;
    font-size: 11pt;

  }
}
