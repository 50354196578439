@media (max-width: 480px) {
  .sp-hidden {
    &.navbar-nav {
      display: none;
    }
  }
  // Top
  .lp-top {
    width: 100%;
    height: 200px;
    margin: 0;
    max-width: none;
    display: inline-flex;
    background: linear-gradient(90deg, #ECEDEA 0%, #F8F8F6 49.48%, #DCDBD8 100%);

    .lp-top-left {
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: center;

      .sentence {
        font-size: 14px;
        margin: auto;
        margin-left: 5%;
      }

      .cv-button {
        margin-left: 30%;
        margin-top: 15%;
        font-size: 16px;
        width: 150%;
        height: 50px;
      }

      .lp-button-register {
        padding: 8px 16px;
        margin-top: 10px;
        font-size: 22px;
        line-height: 33px;
        width: 191px;
        height: 54px;
        /* gradation */
        background: linear-gradient(90deg, #0F6BBF 0%, #4285EC 100%);
        border-radius: 30px;
      }
    }

    .lp-top-right {
      width: 35%;
      height: 45%;
      margin: 0;
      margin-top: 5%;
      margin-right: 2%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('../assets/image/top.png');
    }
  }

  .lp-concept {
    height: 270px;
    margin: 0;
    max-width: none;
    display: inline-block;
    background-color: #ffffff;

    .lp-concept-left {
      width: 100%;
      height: 60px;
      margin: 0;
      margin-top: 10%;
      background-size: 250px;
      background-repeat: no-repeat;
      background-position: top;
      background-image: url('../assets/image/logo_yoko.png');
    }

    .lp-concept-right {
      margin-top: 0;

      h2 {
        font-size: 28px;
        margin-bottom: 10px;
      }

      span {
        font-size: 15px;
        margin-bottom: 0;
        display: contents;
        text-align: justify;
      }
    }
  }

  .lp-feature {
    margin: 0;
    margin-top: -6px;
    max-width: none;
    display: grid;
    justify-content: center;
    background-color: #F8F8F6;

    .accent-1 {
      margin-top: 30px;
    }

    .lp-feature-box {
      width: 100%;
      height: 474px;
      margin-top: 50px;
      background: #FFFFFF;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
      border-radius: 16px;
      display: block;

      &.number-2 {
        width: 100%;
        .lp-feature-box-left {
          width: 100%;
        }
      }

      .lp-feature-box-left {
        width: 48%;
        height: 120px;
        margin: 0;
        &.number-2 {
          .number {
            margin: 0;
          }
        }
      }
      .lp-feature-box-right {
        width: 100%;
        height: 314px;
        margin: 0;
        h3 {
          padding-left: 0;
        }
        span {
          padding-left: 0;
        }
      }
      .feature-img1 {
        margin-right: 30%;
        margin-left: 30%;
        margin-top: 8%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('../assets/image/feature01.png');
      }
      .feature-img2 {
        height: 75%;
        margin: 0;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50% 220px;
        background-image: url('../assets/image/feature02.png');
      }
      .feature-img3 {
        width: 100%;
        margin-bottom: -6%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('../assets/image/feature03.png');
      }
      .number {
        text-align: center;
        padding-left: 0;
        font-size: 64px;
        color: #0F6BBF;
        display: block;
        margin-top: 0;
      }
      span {
        margin-bottom: 0;
        display: contents;
        text-align:justify;
      }
      h3 {
        padding-left: 0;
        text-align: center;
        margin-bottom: 20px;
        font-size: 40px;
        color: #222222;
      }
    }
  }

  .lp-price {
    margin: 0;
    max-width: none;
    display: grid;
    justify-content: center;
    background-color: #ffffff;

    h2 {
      margin-top: 10%;
    }

    table {
      border-collapse: collapse;
      font-size: 15px;
      margin-top: 0;
      tr {
        height: 70px;
        border-bottom: solid 1px #999999;

        &:last-child th{
          vertical-align: top;
          padding-top: 25px;
        }
      }
      th,td {
        padding: 10px
      }
      th {
        color: #999999;
        width: 160px;
      }
      strong.number {
        font-size: 40px;
        color: #0F6BBF;
      }
      ul {
        margin-top: 15px;
        margin-left: -20px;
      }
    }
  }

  .lp-recommend {
    margin: 0;
    max-width: none;
    display: grid;
    justify-content: center;
    background-color: #F8F8F6;

    h2 {
      margin-top: 10%;
    }

    span {
      display: block;
      text-align: center;
    }

    .recommend-row {
      max-width: 880px;
      margin: auto;
    }
    .recommend-box {
      width: 100%;
      margin-top: 0;
      margin-bottom: 10%;
      display: grid;
      justify-content: center;
      div {
        height: 120px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
      span {
        font-size: 20px;
      }
      .recommend-img1{
        background-image: url('../assets/image/recommend01.png');
        background-position:22px 10px;
      }
      .recommend-img2{
        background-image: url('../assets/image/recommend02.png');
      }
      .recommend-img3{
        background-image: url('../assets/image/recommend03.png');
      }
    }
  }
  .lp-vision {
    height: 100%;
    margin: 0;
    max-width: none;
    justify-content: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../assets/image/vision.png');
    .accent-1,.accent-2 {
      color: #FFFFFF;
    }
    .vision-box {
      width: 100%;
      padding: 11px 20px 10px 20px;
      color: #FFFFFF;
      background: rgba(0, 0, 0, 0.7);
      div {
        margin-bottom: 40px;
      }
      span {
        display: block;
        font-size: 16px;
        line-height: 28px;
      }
      .title span{
        font-size: 40px;
        font-weight: 400;
        line-height: 3.5rem;
      }
      ul {
        padding-left: 20px;
        li {
          margin-bottom: 20px;
        }
      }
    }
  }

  .lp-faq {
    margin: 0;
    max-width: none;
    display: grid;
    justify-content: center;
    background-color: #F8F8F6;

    h2 {
      margin-top: 10%;
    }

    .accordion {
      margin-top: 10%;
      .accordion-item {
        width: 100%;
        .accordion-header {
          height: 92px;
          display: flex;
        }
      }
      strong {
        margin-right: 5px;
        &.blue {
          color: #0f6bbf;
        }
        &.red {
          color: #ff1154;
        }
      }
    }
  }

  .cv-container {
    margin: 5% 0 10% 0;
  }

  .navbar-nav {
    display: inline;
  }
}
